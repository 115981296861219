<template>
  <v-img
    ref="image"
    @click.prevent="onOpenClicked"
    :lazy-src="lazySrc"
    :src="src"
    :contain="contain"
    :aspect-ratio="aspectRatio"
    class="image-style"
  >
    <template v-slot:placeholder>
      <v-layout fill-height align-center justify-center ma-0>
        <v-progress-circular indeterminate color></v-progress-circular>
      </v-layout>
    </template>

    <v-layout v-if="!hideEditButtons" class="header-style">
      <DefaultButton v-if="value.type == 0" @click.stop="editImage()" icon>
        <v-icon small>mdi-pencil</v-icon>
      </DefaultButton>
      <v-spacer></v-spacer>
      <DefaultButton @click.stop="deleteFile(value)" icon bgColor="error">
        <v-icon small>mdi-close</v-icon>
      </DefaultButton>
    </v-layout>
    <v-layout v-if="!hideDownloadButtons" class="header-style">
      <DefaultButton
        @click.stop="downloadFile(false)"
        icon
      >
        <v-icon small>mdi-download</v-icon>
      </DefaultButton>
      <DefaultButton
        v-if="value.type == 0 && !readonly && value.editable"
        @click.stop="onEditClicked"
        icon
      >
        <v-icon small>mdi-pencil</v-icon>
      </DefaultButton>
      <v-spacer></v-spacer>
      <DefaultButton
        v-if="!readonly && value.editable"
        @click.stop="onDeleteClicked"
        icon
        bgColor="error"
      >
        <v-icon small>mdi-trash-can-outline</v-icon>
      </DefaultButton>
    </v-layout>
    <v-layout v-if="!hideDescription" class="description-style">
      <v-flex xs8>
        <v-layout column>
          <div
            class="
              value-text
              subtitle
              font-weight-bold
              my-1
              ml-2
              d-inline-block
              text-wrap
            "
          >
            {{ value.title }}
          </div>
          <div class="text-caption my-1 ml-2">{{ value.created }}</div>
        </v-layout>
      </v-flex>
      <v-flex xs4>
        <v-layout column align-end>
          <div class="text-caption d-inline-block my-1 ml-1 mr-2">
            {{ value.human_size }}
          </div>
          <v-spacer></v-spacer>
          <div class="text-caption d-inline-block my-1 ml-1 mr-2">
            {{ value.added_by }}
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <EditImagePopup
      v-if="showEditImagePopup"
      :showPopup="showEditImagePopup"
      :value="value"
      @cancel="
        () => {
          this.showEditImagePopup = false;
        }
      "
      @saveEditedImage="saveEditedImage"
    >
    </EditImagePopup>
  </v-img>
</template>

<script>
import PatientService from "@/services/patient.service";

export const IMAGE = 0;
// eslint-disable-next-line no-unused-vars
export const DOCUMENT = 1;
export const PDF = 2;
// eslint-disable-next-line no-unused-vars
export const UNKNOWN = 3;

export default {
  data() {
    return {
      src: "",
      showEditImagePopup: false,
      lazySrc: require("@/assets/image_big.png"),
      intersected: false,
      observer: null,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    EditImagePopup: () => import("@/components/popups/EditImagePopup"),
  },
  props: {
    value: {},
    aspectRatio: {
      default: "1",
    },
    contain: {
      default: false,
    },
    hideDescription: {
      default: false,
    },
    hideEditButtons: {
      type: Boolean,
      default: true,
    },
    hideDownloadButtons: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    miniature: {
      type: Boolean,
      default: false,
    },
    skipIntersection: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    intersected() {
      this.load();
    },
    value() {
      this.placeholder();
      this.load();
    },
  },
  methods: {
    editImage() {
      this.showEditImagePopup = true;
    },
    onEditClicked() {
      this.$emit("edit");
    },
    deleteFile(file) {
      this.$emit("deleteFile", file);
    },
    onDeleteClicked() {
      this.$emit("delete");
    },
    onOpenClicked() {
      if (this.value.type == IMAGE) {
        this.$emit("open");
      } else if (this.value.type == PDF) {
        this.$emit("openPdf");
      } else {
        this.downloadFile(false);
      }
    },
    saveEditedImage(value) {
      this.showEditImagePopup = false;
      this.$emit("saveEditedImage", value);
    },
    downloadFile(load) {
      return PatientService.downloadDocument(
        process.env.VUE_APP_URL_BACKEND +
          (this.miniature && load ? this.value.miniature : this.value.file)
      )
        .then((x) => {
          if (load) {
            let blob = new Blob([x.data], { type: x.headers["content-type"] });
            this.src = URL.createObjectURL(blob);
          } else {
            const url = window.URL.createObjectURL(new Blob([x.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.value.title); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((x) => {
          console.log("ERROR", x);
        });
    },
    load() {
      if (!this.intersected) {
        return;
      } else if (this.value.type == IMAGE) {
        if (!!this.value.image) {
          this.src = this.value.image;
        } else {
          this.downloadFile(true);
        }
      }
    },
    placeholder() {
      switch (this.value.type) {
        case IMAGE:
          this.src = require("@/assets/image_big.png");
          break;
        case PDF:
          this.src = require("@/assets/pdf_big.png");
          break;
        default:
          this.src = require("@/assets/doc_big.png");
          break;
      }
    },
  },
  created() {
    this.placeholder();
  },
  mounted() {
    if (!("IntersectionObserver" in window)) {
      this.intersected = true;
      return;
    }
    this.observer = new IntersectionObserver((entries) => {
      const element = entries[0];
      if (element.isIntersecting || this.skipIntersection) {
        this.intersected = true;
        this.observer.disconnect();
      }
    });
    this.observer.observe(this.$el);
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped class="css">
.header-style {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.description-style {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  text-align: left !important;
  bottom: 0;
  left: 0;
  right: 0;
}
.value-text {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-style {
  background-color: #f6f8fc;
  cursor: pointer;
}
</style>
